import React from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Grid,
  Container,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import ProductCard from "./ProductCard";
import SmallCountrySelect from "./SmallCountrySelect";
import { countries } from "../utils/countries";

const ProductListingPage = ({
  products,
  setOriginCountry,
  setDestinationCountry,
  originCountry,
  destinationCountry,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOriginCountrySelect = (event, value) => {
    if (value) {
      setOriginCountry(value.label);
    }
  };

  const handleDestinationCountrySelect = (event, value) => {
    if (value) {
      setDestinationCountry(value.label);
    }
  };

  return (
    <Container maxWidth="false" disableGutters>
      <AppBar position="static" color="default" elevation={3} sx={{ mb: 3 }}>
        <Toolbar
          sx={{
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            backgroundColor: "#2e6c7b",
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            sx={{ height: 60 }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/logo-appbar.png`}
              alt="BestBuyCountry Logo"
              style={{ height: "100%", objectFit: "contain" }}
            />
          </IconButton>
          <Box
            sx={{
              width: isMobile ? "100%" : "auto",
              display: "flex",
              justifyContent: "center",
              flexDirection: isMobile ? "row" : "row",
              alignItems: "center",
              marginBottom: isMobile ? 1 : 0,
            }}
          >
            <SmallCountrySelect
              label="Home"
              value={originCountry}
              onCountrySelect={handleOriginCountrySelect}
              dropDownOptions={[{ label: "India" }]}
              sx={{ marginRight: isMobile ? 1 : 0 }}
            />
            <SmallCountrySelect
              label="Destination"
              value={destinationCountry}
              onCountrySelect={handleDestinationCountrySelect}
              dropDownOptions={countries}
            />
          </Box>
        </Toolbar>
      </AppBar>
      {originCountry && destinationCountry && (
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          style={{
            textAlign: "center",
            margin: isMobile ? "10px 0" : "40px 0",
            fontWeight: "bold",
            color: "#2e6c7b",
            fontSize: isMobile ? "1.2rem" : "2.25rem",
          }}
        >
          Best Deals in {destinationCountry}!
        </Typography>
      )}
      <Grid container spacing={2} padding={4}>
        {products.map((product, index) => (
          <Grid item xs={12} sm={isMobile ? 12 : 6} key={index}>
            <ProductCard product={product} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ProductListingPage;
