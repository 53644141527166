import React from "react";
import { Box, Card, CardMedia, CardContent, Typography, IconButton } from "@mui/material";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const ProductCard = ({ product }) => {
  const {
    product: productName,
    variation: variationName,
    origin_price: originPrice,
    origin_currency: originCurrency,
    destination_price: destinationPrice,
    destination_currency: destinationCurrency,
    origin_price_usd: originPriceUSD,
    destination_price_usd: destinationPriceUSD,
    url: product_url,
  } = product;

  const imageUrl = `${process.env.PUBLIC_URL}/${productName}.jpg`;
  const defaultImageUrl = process.env.PUBLIC_URL + "/logo192.png";

  const priceDifference =
    ((destinationPriceUSD - originPriceUSD) / originPriceUSD) * 100;
  const priceComparison =
    priceDifference > 0
      ? `${priceDifference.toFixed(0)}% Expensive`
      : `${Math.abs(priceDifference).toFixed(0)}% Cheaper`;

  const destinationPriceInOriginCurrency = (originPrice * (1 - priceDifference / 100)).toFixed(0);
  
  const handleShopClick = () => {
    window.open(product_url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Card sx={{ display: "flex", mb: 2, position: 'relative' }}>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={imageUrl}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = defaultImageUrl;
        }}
        alt={productName}
      />
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" variant="h5">
            {productName}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {`${destinationPriceInOriginCurrency} ${originCurrency}`}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {`(${destinationPrice} ${destinationCurrency} for ${variationName})`}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.primary"
            component="div"
          >
            {priceComparison}
          </Typography>
        </CardContent>
      </Box>
      <IconButton
        aria-label="shop"
        onClick={handleShopClick}
        sx={{
          position: 'absolute',
          top: 10,
          right: 10,
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'lightgray',
          },
        }}
      >
        <ShoppingCartIcon />
      </IconButton>
    </Card>
  );
};

export default ProductCard;
