import React, { useEffect, useCallback, useState } from "react";
import { Route, Routes } from "react-router-dom";
import DestinationPage from "./DestinationPage";
import OriginPage from "./OriginPage";
import ProductListingPage from "./ProductListingPage";
import useTrackPageViews from "../hooks/useTrackPageViews";
import Loader from "./Loader";

const Main = () => {
  useTrackPageViews();

  const [originCountry, setOriginCountry] = useState("");
  const [destinationCountry, setDestinationCountry] = useState("");
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchRecommendedProducts = useCallback(async () => {
    if (!originCountry || !destinationCountry) return;

    setLoading(true);
    const startTime = Date.now();

    const response = await fetch(
      `https://api.bestbuycountry.com/product-list?origin=${originCountry}&destination=${destinationCountry}`
    );
    const data = await response.json();

    const fetchDuration = Date.now() - startTime;
    const remainingTime = 2000 - fetchDuration;

    setTimeout(
      () => {
        setRecommendedProducts(data.data);
        setLoading(false);
      },
      remainingTime > 0 ? remainingTime : 0
    );
  }, [originCountry, destinationCountry]);

  useEffect(() => {
    fetchRecommendedProducts();
  }, [originCountry, destinationCountry, fetchRecommendedProducts]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <DestinationPage setDestinationCountry={setDestinationCountry} />
        }
      />
      <Route
        path="/origin"
        element={<OriginPage setOriginCountry={setOriginCountry} />}
      />
      <Route
        path="/products"
        element={
          loading ? (
            <Loader />
          ) : (
            <ProductListingPage
              products={recommendedProducts}
              setOriginCountry={setOriginCountry}
              setDestinationCountry={setDestinationCountry}
              originCountry={originCountry}
              destinationCountry={destinationCountry}
            />
          )
        }
      />
    </Routes>
  );
};

export default Main;
