import React from 'react';
import { TextField, Box, Autocomplete, Typography } from '@mui/material';
import { styled, keyframes } from '@mui/system';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  animation: ${fadeIn} 2s ease-in-out;
`;

const StyledAutocomplete = styled(Autocomplete)`
  width: 80%;
  max-width: 500px;
`;

const CountrySelect = ({ title, onCountrySelect, dropDownOptions }) => {
  return (
    <StyledBox>
      <Typography variant="h1" sx={{ marginBottom: 2, fontSize: '2.5rem', textAlign: 'center', animation: `${fadeIn} 3s ease-in-out` }}>
        {title}
      </Typography>
      <StyledAutocomplete
        options={dropDownOptions}
        getOptionLabel={(option) => option.label}
        onChange={onCountrySelect}
        renderInput={(params) => <TextField {...params} label="Select a country" variant="outlined" />}
      />
    </StyledBox>
  );
};

export default CountrySelect;
