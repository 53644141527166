import React from 'react';
import { useNavigate } from 'react-router-dom';
import CountrySelect from './CountrySelect';

const OriginPage = ({ setOriginCountry }) => {
  const navigate = useNavigate();
  const dropDownOptions = [
    { label: 'India' },
  ]
  const handleCountrySelect = (event, value) => {
    console.log(value);
    if (value) {
      setOriginCountry(value.label);
      navigate('/products');
    }
  };

  return <CountrySelect title="Select Your Home Country" onCountrySelect={handleCountrySelect} dropDownOptions={dropDownOptions}/>;
};

export default OriginPage;
