import React from 'react';
import { useNavigate } from 'react-router-dom';
import CountrySelect from './CountrySelect';
import { countries } from '../utils/countries.js';


const DestinationPage = ({ setDestinationCountry }) => {
  const navigate = useNavigate();

  const dropDownOptions = countries;
  const handleCountrySelect = (event, value) => {
    console.log(value);
    if (value) {
      setDestinationCountry(value.label);
      navigate('/origin');
    }
  };

  return <CountrySelect title="Where are you heading?" onCountrySelect={handleCountrySelect} dropDownOptions={dropDownOptions}/>;
};

export default DestinationPage;
