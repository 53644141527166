import React from 'react';
import { TextField, Autocomplete } from '@mui/material';

const SmallCountrySelect = ({ label, value, onCountrySelect, dropDownOptions }) => {
  // Define a style object that uses a media query for smaller screens
  const autoCompleteStyle = {
    width: '180px',
    mx: 1,
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
      '& input': {
        color: 'white',
      },
    },
    '& .MuiInputLabel-outlined': {
      color: 'white',
    },
    '@media (max-width:600px)': {
      width: '150px', 
    },
  };

  return (
    <Autocomplete
      options={dropDownOptions}
      getOptionLabel={(option) => option.label}
      value={value ? { label: value } : null}
      onChange={onCountrySelect}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          size="small"
          sx={autoCompleteStyle}
        />
      )}
      sx={autoCompleteStyle}
    />
  );
};

export default SmallCountrySelect;
