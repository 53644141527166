import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Main from './components/Main';
import ReactGA from 'react-ga4';
import RequestCountryPopup from './components/RequestCountryPopup';

ReactGA.initialize('G-QTLZJ4XBN5');

const App = () => {
  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      <Router>
        <Main />
      </Router>
      <RequestCountryPopup />
    </div>
  );
};

export default App;
