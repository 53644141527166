import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from "@mui/material";

const RequestCountryPopup = () => {
  const [open, setOpen] = useState(false);
  const [homeCountry, setHomeCountry] = useState("");
  const [destinationCountry, setDestinationCountry] = useState("");
  const [specificThings, setSpecificThings] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [homeCountryError, setHomeCountryError] = useState("");
  const [destinationCountryError, setDestinationCountryError] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (email && !validateEmail(email)) {
      setEmailError("Please enter a valid email");
      return;
    }
    setEmailError("");

    if (!homeCountry) {
      setHomeCountryError("Please enter your home country");
      return;
    }
    setHomeCountryError("");

    if (!destinationCountry) {
      setDestinationCountryError("Please enter the destination country");
      return;
    }
    setDestinationCountryError("");

    const data = {
      home_country: homeCountry,
      destination_country: destinationCountry,
      product: specificThings,
      email: email,
    };

    const queryString = new URLSearchParams(data).toString();

    try {
      const response = await fetch(
        `https://api.bestbuycountry.com/feature-requests?${queryString}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        handleClose();
        // handle success
      } else {
        // handle error
      }
    } catch (error) {
      // handle error
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          position: "fixed",
          bottom: 16,
          right: { xs: '50%', sm: 16 }, // For small screens and up, use 16px from the right. For extra-small screens, center it by setting right to 50%
          transform: { xs: 'translateX(50%)', sm: 'none' }, // For extra-small screens, adjust for the translation to truly center. No adjustment needed for small screens and up.
          backgroundColor: "#2e6c7b",
          ":hover": {
            backgroundColor: "#2e6c7b",
            fontWeight: "bold",
          },
          textTransform: "none",
          fontSize: { xs: '0.8rem', sm: "1rem" }
        }}
      >
        Your Country Not Listed?
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Hey there! This is{" "}
          <a
            href="https://x.com/ajinkya_shejul"
            target="_blank"
            rel="noopener noreferrer"
          >
            @Ajinkya
          </a>
          , the creator of BestBuyCountry. I'm expanding our list of supported
          countries and products. Please fill out the form so I can prioritize your ask. Also, share your email so I can notify you when your
          requested country is added. Thank you!
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              marginTop: 2,
            }}
          >
            <TextField
              label="Home Country"
              value={homeCountry}
              onChange={(e) => setHomeCountry(e.target.value)}
              error={!!homeCountryError}
              helperText={homeCountryError}
              fullWidth
              required
            />
            <TextField
              label="Destination Country"
              value={destinationCountry}
              onChange={(e) => setDestinationCountry(e.target.value)}
              error={!!destinationCountryError}
              helperText={destinationCountryError}
              fullWidth
              required
            />
            <TextField
              label="Specific Things You Want to Buy"
              value={specificThings}
              onChange={(e) => setSpecificThings(e.target.value)}
              fullWidth
            />
            <TextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!emailError}
              helperText={emailError}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RequestCountryPopup;
