export const countries = [
  { label: 'Australia' },
  // { label: 'Brasil (Brazil)' },
  { label: 'Canada' },
  { label: 'Chile' },
  { label: 'Danmark (Denmark)' },
  { label: 'Deutschland (Germany)' },
  { label: 'España (Spain)' },
  { label: 'France' },
  { label: 'Hong Kong' },
  // { label: 'India' },
  { label: 'Ireland' },
  { label: 'Italia (Italy)' },
  { label: 'Japan' },
  { label: 'Korea' },
  { label: 'Luxembourg' },
  { label: 'Magyarország (Hungary)' },
  { label: 'Malaysia' },
  { label: 'México (Mexico)' },
  { label: 'Nederland (Netherlands)' },
  { label: 'New Zealand' },
  { label: 'Norge (Norway)' },
  { label: 'Österreich (Austria)' },
  { label: 'Philippines' },
  { label: 'Polska (Poland)' },
  { label: 'Portugal' },
  { label: 'Singapore' },
  { label: 'Suomi (Finland)' },
  { label: 'Sverige (Sweden)' },
  { label: 'Taiwan' },
  { label: 'Thailand' },
  // { label: 'Türkiye (Turkey)' },
  { label: 'United Arab Emirates (UAE)' },
  { label: 'United Kingdom (UK)' },
  { label: 'United States (USA)' },
  { label: 'Việt-Nam (Vietnam)' },
  { label: 'Česko (Czech Republic)' },
];
